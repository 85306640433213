/**
 * @description 封装用户相关的接口
 */

import Ajax from "@/utils/request.js";
import { LOGIN_URL ,USER_URL} from "@/config/user.cfg";


/** 用户登录接口 -- start -- */
export function userLoginApi(data) {
    return Ajax({
        url: LOGIN_URL,
        method: 'POST',
        data
    })
}

/** 用户列表接口 */

export function userListApi(params) {
    return Ajax({
        url: USER_URL,
        method: 'GET',
        params
    })
}

/** 用户新增接口 */

export function userAddApi(data) {
    return Ajax({
        url: USER_URL,
        method: 'POST',
        data
    })
}

/** 用户删除接口 */

export function userDeleteApi(id) {
    return Ajax({
        url: USER_URL + '/' + id,
        method: 'DELETE'
    })
}

/** 用户修改状态接口 */

export function userStatusApi(data) {
    return Ajax({
        url: USER_URL + '/' + data.id+'/status',
        method: 'PUT',
        data
    })
}
/** 用户修改详情接口 */

export function userUpdateApi(data) {
    return Ajax({
        url: USER_URL + '/' + data.id,
        method: 'PUT',
        data
    })
}